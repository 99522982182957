import { ConfigContext } from "context/ConfigContext";
import { useEffect, useState, useContext } from "react";
import colors from "./colors";

export const useThemeHook = () => {
  let configContext = useContext(ConfigContext);

  let bannerBackground = {
    background: "#FEEFE8",
  };

  let buttonPrimary = {
    background: configContext?.config?.theme
      ? configContext?.config?.theme?.palette?.secondary?.main: "#6466F1",
  }

  let buttonPrimaryText = configContext?.config?.theme
    ? { fontWeight: 600 }
    : {
        color: "#FFFFFF",
        fontWeight: 600,
      };
  let buttonDisabled = {
    background: "#F5F5F5",
  };

  let headerBackground = {
    background: configContext?.config?.theme
      ? configContext?.config?.theme?.palette?.primary?.main
      : "#242E49",
  };

  let gradientTextMain = configContext?.config?.theme
    ? { color: configContext?.config?.theme?.palette?.primary?.main }
    : {
        background: "linear-gradient(91.18deg, #6424C5 0%, #1A52BD 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      };

  let gradientText = {
    color: "#242E49"
  };

  let gradientTextBlack = configContext?.config?.theme
    ? { color: configContext?.config?.theme?.palette?.primary?.main }
    : { color: colors.Dark.title };

  let gradientBackground = configContext?.config?.theme
    ? { marginTop: 8, borderRadius: 12, padding: 16, paddingBottom: 0 }
    : {
        marginTop: 8,
        background:
          "linear-gradient(161.38deg, rgba(242, 225, 254, 0.1) 41.6%, rgba(199, 223, 255, 0.1) 74.8%), linear-gradient(71.55deg, rgba(242, 225, 254, 0.1) 40.97%, rgba(199, 223, 255, 0.1) 74.98%), #FFFFFF",
        borderRadius: 12,
        padding: 16,
        paddingBottom: 0,
      };

  let gradientBackgroundGrey = configContext?.config?.theme
    ? {
        marginTop: 8,
        borderRadius: 12,
        padding: 16,
        paddingBottom: 0,
        background: colors.Grey.placeholder,
      }
    : {
        marginTop: 8,
        background:
          "linear-gradient(161.38deg, rgba(242, 225, 254, 0.1) 41.6%, rgba(199, 223, 255, 0.1) 74.8%), linear-gradient(71.55deg, rgba(242, 225, 254, 0.1) 40.97%, rgba(199, 223, 255, 0.1) 74.98%), #FFFFFF",
        borderRadius: 12,
        padding: 16,
        paddingBottom: 0,
      };

  const [theme, setTheme] = useState({
    buttonPrimary,
    buttonPrimaryText,
    buttonDisabled,
    headerBackground,
    gradientText,
    gradientTextMain,
    gradientBackground,
    gradientTextBlack,
    gradientBackgroundGrey,
    bannerBackground,
  });

  useEffect(() => {
    buttonPrimary = {
      background: configContext?.config?.theme
        ? configContext?.config?.theme?.palette?.secondary?.main: "#6466F1",
    }

    bannerBackground = {
      background: "#FEEFE8",
    };

    buttonPrimaryText = configContext?.config?.theme
      ? { fontWeight: 600 }
      : {
          color: "#FFFFFF",
          fontWeight: 600,
        };
    buttonDisabled = {
      background: "#F5F5F5",
    };

    gradientTextBlack = configContext?.config?.theme
      ? { color: configContext?.config?.theme?.palette?.primary?.main }
      : { color: colors.Dark.title };

    headerBackground = {
      background: configContext?.config?.theme
        ? configContext?.config?.theme?.palette?.primary?.main
        : "linear-gradient(161.38deg, rgba(242, 225, 254, 0.1) 41.6%, rgba(199, 223, 255, 0.1) 74.8%), linear-gradient(71.55deg, rgba(242, 225, 254, 0.1) 40.97%, rgba(199, 223, 255, 0.1) 74.98%)",
    };

    gradientTextMain = configContext?.config?.theme
      ? { color: configContext?.config?.theme?.palette?.primary?.main }
      : {
          background: "linear-gradient(91.18deg, #6424C5 0%, #1A52BD 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        };

        gradientText = {
          color: "#242E49"
        };
    gradientBackground = configContext?.config?.theme
      ? { marginTop: 8, borderRadius: 12, padding: 16, paddingBottom: 0 }
      : {
          marginTop: 8,
          background:
            "linear-gradient(161.38deg, rgba(242, 225, 254, 0.1) 41.6%, rgba(199, 223, 255, 0.1) 74.8%), linear-gradient(71.55deg, rgba(242, 225, 254, 0.1) 40.97%, rgba(199, 223, 255, 0.1) 74.98%), #FFFFFF",
          borderRadius: 12,
          padding: 16,
          paddingBottom: 0,
        };

    gradientBackgroundGrey = configContext?.config?.theme
      ? {
          marginTop: 8,
          borderRadius: 12,
          padding: 16,
          paddingBottom: 0,
          background: "#FAFBFB",
        }
      : {
          marginTop: 8,
          background:
            "linear-gradient(161.38deg, rgba(242, 225, 254, 0.1) 41.6%, rgba(199, 223, 255, 0.1) 74.8%), linear-gradient(71.55deg, rgba(242, 225, 254, 0.1) 40.97%, rgba(199, 223, 255, 0.1) 74.98%), #FFFFFF",
          borderRadius: 12,
          padding: 16,
          paddingBottom: 0,
        };
    setTheme({
      buttonPrimary,
      buttonPrimaryText,
      buttonDisabled,
      headerBackground,
      gradientText,
      bannerBackground,
      gradientBackground,
      gradientBackgroundGrey,
    });
  }, [...Object.values(configContext)]);

  return [theme];
};

export const buttonPrimary = {
  background: "#6466F1",
};

export const buttonPrimaryText = {
  color: "#FFFFFF",
  fontWeight: 600,
};
export const buttonDisabled = {
  background: "#F5F5F5",
};

export const headerBackground = {
  background: "#242E49",
};

export const gradientText = {
  color: "#242E49"
};

const successScreenPrimary = {
  background: "#FFFFFF",
};
const successScreenPending = {
  background:
    "var(--frame-neutral, linear-gradient(180deg, #E3E2F9 0%, #E7EDF9 11.20%, #FFF 100%))",
};
const successScreenSuccess = {
  background:
    "var(--frame-positive, linear-gradient(180deg, #E4F8F2 0%, #F4FCFA 28.41%, #FEFFFF 38.02%, #FFF 100%))",
};
export const successThemeMap = {
  pending: successScreenPending,
  primary: successScreenPrimary,
  success: successScreenSuccess,
};
