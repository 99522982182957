import {
  conversionBuyMenthods,
  getOnRampTransaction,
  getOnRampWithdrawTransaction
} from "api/onRampHooks";
import OffRampSuccess from "components/offRamp/OffRampSuccess";
import InvalidNetworkBS from "components/onRamp/InvalidNetwork";
import { AutoClose } from "components/shared/AutoClose";
import LoadingScreen from "components/shared/LoadingScreen";
import { ScrollView } from "components/shared/ScrollView";
import { useBackStackContext } from "context/BackStackContext";
import { ConfigContext } from "context/ConfigContext";
import { KycChecker } from "context/KycChecker";
import { useOnrampContext } from "context/OnRamp";
import { useUserContext } from "context/UserContext";
import { Authenticated } from "context/auth/auth-context";
import { AuthConsumer, useAuth } from "context/auth/use-auth";
import { CheckoutContainer } from "onramp/checkout/checkout-container";
import AddFunds from "pages/onRamp/AddFunds";
import BankTransferContainer from "pages/onRamp/BankTransferContainer";
import ChoosePaymentsMethodContainer from "pages/onRamp/ChoosePaymentMethods";
import UPITransferContainer from "pages/onRamp/UPITransferContainer";
import WalletScreenContainer from "pages/onRamp/WalletScreenContainer";
import { useContext, useEffect, useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Flow } from "types/flow";
import { UpiIntentContainer } from "./UpiIntent/container";
import StateEnum from "./states";
import "./styles.css";

const OnRamp = ({
  onSuccess,
  init,
  startKYC,
  external,
  externalAddress = null,
  createdOrderId = "",
  cryptoSymbol,
  paymentMethod,
  transactionId,
  item,
  redirectUrl,
  presetKycCountry,
  fixedPresetKycCountry,
  externalDigilocker
}) => {

  const { fiatAmount, cryptoAmount, fiatCurrencyState, cryptoCurrencyState, quote, withdrawNetworkState, withdrawAddress, paymentMethods, selectedPaymentMethod, selectedPaymentApp } = useOnrampContext()
  const [isLoadingMethods, setIsLoadingMethods] = useState(true);

  const setSelectedPaymentMethod = selectedPaymentMethod?.setValue
  const setSelectedPaymentApp = selectedPaymentApp?.setValue
  const selectedFiatCurrency = fiatCurrencyState.fiatCurrency?.value
  const selectedCryptoCurrency = cryptoCurrencyState.value
  const conversionDetails = quote.data
  const externalNetwork = withdrawNetworkState?.selectedNetwork?.value?.id

  const auth = useAuth()
  const authComplete = (auth.state instanceof Authenticated)

  const user = useUserContext().user
  const bankId = user?.bankAccounts?.[0]?.bank_id
  const accountNumber = user?.bankAccounts?.[0]?.account_number
  const bankIfsc = user?.bankAccounts?.[0]?.ifsc_code

  const configContext = useContext(ConfigContext);
  const { current: currentState, goTo: setCurrentState, goBack } = useBackStackContext()

  const { value: walletAddress, setValue: setWalletAddress } = withdrawAddress
  const [showLimitError, setShowLimitError] = useState(false);
  const [selectedCurrencies, setSelectedCurrency] = useState(null);
  const [areConversionDetailsLoading, setAreConversionDetailsLoading] =
    useState(false);
  const [currenciesList, setCurrenciesList] = useState(null);
  const [checkingLimits, setCheckingLimits] = useState(false);
  const [showCrashError, setShowCrashError] = useState(false);
  const [orderId, setOrderId] = useState(createdOrderId);
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentCreatedAt, setPaymentCreatedAt] = useState("");
  const addFundsRef = useRef(null);
  const loadingRef = useRef(null);
  const paymentMethodsRef = useRef(null);
  const upiRef = useRef(null);
  const bankRef = useRef(null);
  const vanRef = useRef(null);
  const walletRef = useRef(null);
  const successRef = useRef(null);
  const checkoutRef = useRef(null);
  const authRef = useRef(null);
  const [errors, setErrors] = useState({});
  const statesToRef = (state) => {
    switch (state) {
      case StateEnum.LOADING:
        return loadingRef;
      case StateEnum.ADD_FUNDS:
        return addFundsRef;
      case StateEnum.PAYMENT_METHODS:
        return paymentMethodsRef;
      case StateEnum.BANK_PAYMENT_METHOD:
        return bankRef;
      case StateEnum.UPI_PAYMENT_METHOD:
        return upiRef;
      case StateEnum.INTENT_PAYMENT_METHOD:
        return upiRef;
      case StateEnum.SUCCESS_SCREEN:
        return successRef;
      case StateEnum.WALLET_ADDRESS:
        return walletRef;
      case StateEnum.PAYMENT_METHOD_CHECKOUT:
        return checkoutRef;
      case StateEnum.VAN_PAYMENT_METHOD:
        return vanRef;
      case StateEnum.AUTH:
        return authRef;
    }
  };

  const getTransaction = async () => {
    try {
      const result = external
        ? await getOnRampWithdrawTransaction({
          init: init,
          id: orderId,
        })
        : await getOnRampTransaction({
          init,
          id: orderId,
        });
      if (result?.success) {
        setOrderDetails(result?.data);
        setPaymentDetails(result?.data, external);
        setCurrentState(StateEnum.SUCCESS_SCREEN);
        return StateEnum.SUCCESS_SCREEN;
      }
    } catch (e) { }
  };

  const initializeState = async () => {
    var initialState = StateEnum.LOADING;
    if (orderId && orderId !== "") {
      return await getTransaction(initialState);
    } else {
      evaluateState()
    }
  }

  const evaluateState = () => {

    if (currentState !== StateEnum.LOADING) return

    let state = StateEnum.LOADING
    let isCheckpoint = true
    setCurrentState(StateEnum.LOADING)

    if (cryptoAmount.locked || fiatAmount.locked) {
      if (!withdrawAddress.fixedPreset) {
        state = StateEnum.WALLET_ADDRESS
      } else {
        state = StateEnum.PAYMENT_METHODS
      }
    }
    //defaul state
    if (state === StateEnum.LOADING) {
      state = StateEnum.ADD_FUNDS;
      isCheckpoint = true
    }

    setCurrentState(state, isCheckpoint);
  };

  const fetchConversionMethods = async () => {
    try {
      const data = await conversionBuyMenthods(init);
      setCurrenciesList(data);
      return data;
    } catch (err) { }
  };

  // const { clientId } = useAuth()

  const amountYouWillGet =
    fiatAmount.value > 0
      ? parseFloat((fiatAmount.value * conversionDetails?.basePrice)?.toFixed(2))
      : 0;

  const mudrexFees = parseFloat(
    (amountYouWillGet * conversionDetails?.mudrexFees)?.toFixed(2)
  );
  const tax = parseFloat(
    (amountYouWillGet * conversionDetails?.tax)?.toFixed(2)
  );

  var finalAmountYouGet = 0;
  finalAmountYouGet = (amountYouWillGet - mudrexFees - tax)?.toFixed(2);

  const submitWalletAddress = () => {
    setCurrentState(StateEnum.PAYMENT_METHODS);
  };

  const setPaymentDetails = (data) => {
    setOrderDetails(data);
    // fiatAmount.setValue(data?.fiat_amount);
    setOrderId(data?.id);
    setPaymentCreatedAt(data?.created_at);
    setPaymentStatus(data?.status);
    setSelectedPaymentMethod(data?.payment_method);
  };

  const proceedFromAddFunds = () => {
    if(fiatAmount.value > 500 && selectedFiatCurrency.symbol === 'INR' && auth.clientId === "18e963ea-39fd-4a1b-b1e6-decbfe791d31") {
      setShowLimitError(true)
      return;
    } else {
      setShowLimitError(false)
    }

    let state = StateEnum.ADD_FUNDS

    if (!withdrawAddress.fixedPreset) {
      state = StateEnum.WALLET_ADDRESS
    } else {
      state = StateEnum.PAYMENT_METHODS
    }

    setCurrentState(state);
  }

  const allowedNetworks = configContext?.config?.features?.withdraw?.allowed_networks
  const withdrawAllowedOnSelectedNetwork = allowedNetworks === undefined
    || !Boolean(externalNetwork)
    || allowedNetworks.includes(externalNetwork)

  const states = (state) => {
    switch (state) {
      case StateEnum.LOADING:
        return <LoadingScreen headerVisible={false} />
      case StateEnum.ADD_FUNDS:
        return (
          <AddFunds
            init={init}
            errors={errors}
            setCurrentState={setCurrentState}
            selectedCurrency={selectedFiatCurrency}
            setSelectedCurrency={setSelectedCurrency}
            inputAmount={fiatAmount.value}
            setInputAmount={fiatAmount.setValue}
            conversionDetails={conversionDetails}
            finalAmountYouGet={finalAmountYouGet}
            areConversionDetailsLoading={areConversionDetailsLoading}
            showCrashError={showCrashError}
            checkingLimits={checkingLimits}
            setShowCrashError={setShowCrashError}
            selectedCryptoCurrency={selectedCryptoCurrency}
            startKYC={startKYC}
            external={external}
            walletAddress={walletAddress}
            setCheckingLimits={setCheckingLimits}
            inputCryptoAmount={cryptoAmount.value}
            setInputCryptoAmount={cryptoAmount.setValue}
            setPaymentDetails={setPaymentDetails}
            goToNextState={proceedFromAddFunds}
            showLimitError={showLimitError}
            externalData={{
              payment_method: paymentMethod,
              to_amount: cryptoAmount.value,
              to_currency: cryptoSymbol,
              from_currency: conversionDetails?.from_currency,
              crypto_wallet_address: walletAddress,
              network: externalNetwork,
              source_id: bankId,
              ...(transactionId && {
                uuid: transactionId,
              }),
            }}
          />
        );
      case StateEnum.WALLET_ADDRESS:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <WalletScreenContainer
                walletAddress={walletAddress}
                setWalletAddress={setWalletAddress}
                onSubmit={submitWalletAddress}
              />
            </KycChecker>
          </AuthConsumer>
        )
      case StateEnum.PAYMENT_METHODS:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <ChoosePaymentsMethodContainer
                isLoadingMethods={isLoadingMethods}
                setIsLoadingMethods={setIsLoadingMethods}
                withdrawAllowedOnSelectedNetwork={withdrawAllowedOnSelectedNetwork}
                currentState={currentState}
                selectedCurrency={selectedFiatCurrency}
                paymentMethods={paymentMethods}
                setCurrentState={setCurrentState}
                fiatAmount={fiatAmount.value}
                bankId={bankId}
                selectedPaymentMethod={selectedPaymentMethod.value}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                selectedPaymentApp={selectedPaymentApp}
                setSelectedPaymentApp={setSelectedPaymentApp}
                external={external}
                priceLock={cryptoAmount.fixedPreset && !fiatAmount.fixedPreset}
                fromAmount={fiatAmount.value}
                toAmount={cryptoAmount.value}
                orderId={orderId}
                setPaymentDetails={setPaymentDetails}
                walletAddress={walletAddress}
                externalNetwork={externalNetwork}
                transactionId={transactionId}
              />
            </KycChecker>
          </AuthConsumer>
        );
      case StateEnum.BANK_PAYMENT_METHOD:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <BankTransferContainer
                init={init}
                selectedCurrency={selectedFiatCurrency}
                inputAmount={fiatAmount.value}
                finalAmountYouGet={finalAmountYouGet}
                selectedPaymentMethod={selectedPaymentMethod.value}
                bankId={bankId}
                accountNumber={accountNumber}
                onSuccess={onSuccess}
                external={external}
                externalNetwork={externalNetwork}
                walletAddress={walletAddress}
                orderId={orderId}
                orderDetails={orderDetails}
                cryptoAmount={cryptoAmount.value}
                setPaymentDetails={setPaymentDetails}
                setCurrentState={setCurrentState}
                transactionId={transactionId}
                userBankIFSC={bankIfsc}
              />
            </KycChecker>
          </AuthConsumer>
        );
      case StateEnum.UPI_PAYMENT_METHOD:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <UPITransferContainer
                init={init}
                selectedCurrency={selectedFiatCurrency}
                inputAmount={fiatAmount.value}
                finalAmountYouGet={finalAmountYouGet}
                paymentMethods={paymentMethods}
                bankId={bankId}
                accountNumber={accountNumber}
                onSuccess={onSuccess}
                external={external}
                externalNetwork={externalNetwork}
                walletAddress={walletAddress}
                orderId={orderId}
                orderDetails={orderDetails}
                setPaymentDetails={setPaymentDetails}
                cryptoAmount={cryptoAmount.value}
                setCurrentState={setCurrentState}
                transactionId={transactionId}
              />
            </KycChecker>
          </AuthConsumer>
        );
      case StateEnum.INTENT_PAYMENT_METHOD:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <UpiIntentContainer
                init={init}
                selectedCurrency={selectedFiatCurrency}
                inputAmount={fiatAmount.value}
                finalAmountYouGet={finalAmountYouGet}
                paymentMethods={paymentMethods}
                bankId={bankId}
                accountNumber={accountNumber}
                onSuccess={onSuccess}
                external={external}
                externalNetwork={externalNetwork}
                walletAddress={walletAddress}
                orderId={orderId}
                orderDetails={orderDetails}
                setPaymentDetails={setPaymentDetails}
                cryptoAmount={cryptoAmount.value}
                setCurrentState={setCurrentState}
                transactionId={transactionId}
                selectedPaymentApp={selectedPaymentApp}
                selectedPaymentMethod={selectedPaymentMethod.value}
                priceLock={cryptoAmount.fixedPreset && !fiatAmount.fixedPreset}
              />
            </KycChecker>
          </AuthConsumer>
        );
      case StateEnum.PAYMENT_METHOD_CHECKOUT:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <CheckoutContainer
                init={init}
                selectedCurrency={selectedFiatCurrency}
                inputAmount={fiatAmount.value}
                finalAmountYouGet={finalAmountYouGet}
                paymentMethods={paymentMethods}
                bankId={bankId}
                accountNumber={accountNumber}
                onSuccess={onSuccess}
                external={external}
                externalNetwork={externalNetwork}
                walletAddress={walletAddress}
                orderId={orderId}
                orderDetails={orderDetails}
                setPaymentDetails={setPaymentDetails}
                cryptoAmount={cryptoAmount.value}
                setCurrentState={setCurrentState}
                transactionId={transactionId}
                selectedPaymentApp={selectedPaymentApp}
                selectedPaymentMethod={selectedPaymentMethod.value}
                priceLock={cryptoAmount.fixedPreset && !fiatAmount.fixedPreset}
              />

            </KycChecker>
          </AuthConsumer>
        )
      case StateEnum.VAN_PAYMENT_METHOD:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              kycRequirements={{ ...configContext?.config?.kyc_requirements?.[Flow.ONRAMP], bank_account: true }}
              externalDigilocker={externalDigilocker}
            >
              <BankTransferContainer
                init={init}
                selectedCurrency={selectedFiatCurrency}
                inputAmount={fiatAmount.value}
                finalAmountYouGet={finalAmountYouGet}
                selectedPaymentMethod={selectedPaymentMethod.value}
                bankId={bankId}
                accountNumber={accountNumber}
                userBankIFSC={bankIfsc}
                onSuccess={onSuccess}
                external={external}
                externalNetwork={externalNetwork}
                walletAddress={walletAddress}
                orderId={orderId}
                orderDetails={orderDetails}
                cryptoAmount={cryptoAmount.value}
                setPaymentDetails={setPaymentDetails}
                setCurrentState={setCurrentState}
                transactionId={transactionId}
              />
            </KycChecker>
          </AuthConsumer>
        );
      case StateEnum.SUCCESS_SCREEN:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <OffRampSuccess
                orderDetails={orderDetails}
                onSuccess={onSuccess}
                paymentMethod={selectedPaymentMethod.value}
                setPaymentMethod={setSelectedPaymentMethod}
                amount={fiatAmount.value}
                createdAt={paymentCreatedAt}
                status={paymentStatus}
                getTransaction={getTransaction}
                external={external}
                item={item}
                redirectUrl={redirectUrl}
              />
            </KycChecker>
          </AuthConsumer>
        );
      case StateEnum.AUTH:
        return (
          <AuthConsumer>
            <KycChecker flow={Flow.ONRAMP}
              presetKycCountry={presetKycCountry}
              fixedPresetKycCountry={fixedPresetKycCountry}
              externalDigilocker={externalDigilocker}
            >
              <AutoClose runner={() => {
                goBack()
              }}
                delayInSeconds={0}
              />
            </KycChecker>
          </AuthConsumer>
        );
    }
  };

  useEffect(() => {
      initializeState();
  }, [authComplete]);

  return (
    <div>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={currentState}
          nodeRef={statesToRef(currentState)}
          addEndListener={(done) => {
            statesToRef(currentState).current.addEventListener(
              "transitionend",
              done,
              false
            );
          }}
          classNames="fade"
        >
          <ScrollView
            id={currentState}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
            ref={statesToRef(currentState)}
          >
            {states(currentState)}
          </ScrollView>
        </CSSTransition>
      </SwitchTransition>

      {!withdrawAllowedOnSelectedNetwork && withdrawNetworkState?.selectedNetwork?.fixedPreset && <InvalidNetworkBS showDrawer={true} />}
    </div>
  );
};

export default OnRamp;
