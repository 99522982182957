import { ThemeProvider } from "@mui/material/styles";
import { ConfigProvider } from "context/ConfigContext";
import { OnrampContextProvider } from "context/OnRamp";
import { UserContextProvider } from "context/UserContext";
import Caas from "pages/caas";
import { useSearchParams } from "react-router-dom";
import themeOptions from "styles/theme";

const OnRamp = ({ }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const signature = searchParams.get("secret")
  const timestamp = searchParams.get("timestamp")
  const userId = searchParams.get("user_id")
  const clientId = searchParams.get("client_id")
  const mode = "DEV"
  const hv_token = searchParams.get("hv_token")

  console.log("clientId", clientId)

  let init

  if (signature && timestamp && userId && clientId && mode) {
    init = async () => {
      return { signature, timestamp, userId, clientId, mode, hv_token };
    };
  }

  const version = searchParams.get("version") ?? "0"

  const network = searchParams.get("network")?.toUpperCase()

  window.sessionStorage.setItem("client_version", version);

  return (
    <>
      <UserContextProvider init={init}>
        <ConfigProvider>
          <ThemeProvider theme={themeOptions}>
            <OnrampContextProvider
              cryptoAmount={searchParams.get("crypto_amount")}
              fiatAmount={searchParams.get("fiat_amount")}
              fiatSymbol={searchParams.get("fiat_currency") ?? searchParams.get("fiat_symbol")}
              suggestedFiatSymbol={searchParams.get("s_fiat_currency") ?? searchParams.get("s_fiat_symbol")}
              network={network}
              cryptoSymbol={searchParams.get("crypto_symbol")}
              suggestedCryptoSymbol={searchParams.get("s_crypto_symbol")}
              withdrawAddress={searchParams.get("wallet_address")}
              paymentMethod={searchParams.get("payment_method")}
            >
              <Caas
                init={init}
                sdkConfiguration={{
                  steps: ["onramp"],
                  kycEnabled: true,
                  bankEnabled: true,
                }}
                onKYCSuccessCallback={() => { }}
                onRampSuccessCallback={() => { }}
                offRampSuccessCallback={() => { }}
                onExceptionCallback={() => {
                  window.location.reload();
                }}
                exceptionCTA={"Try again"}
                fiatAmount={searchParams.get("fiat_amount")}
                startCustomKYCCallback={() => { }}
                onBack={() => { }}
                withdrawNetwork={network}
                withdrawAddress={searchParams.get("wallet_address")}
                transactionId={searchParams.get("transaction_id")}
                redirectUrl={searchParams.get("redirect_url")}
                cryptoAmount={searchParams.get("crypto_amount")}
                cryptoSymbol={searchParams.get("crypto_symbol")}
                orderId={searchParams.get("order_id")}
                expiresAt={searchParams.get("expires_at")}
                paymentMethod={searchParams.get("payment_method")}
                withdraw={searchParams.get("withdraw") ?? true}
                presetKycCountry={searchParams.get("kyc_country") ?? "IND"}
                fixedPresetKycCountry={!(searchParams.get("show_kyc_country_picker") ?? false)}
                externalDigilocker={searchParams.get("ext_digilocker")}
              />
            </OnrampContextProvider>
          </ThemeProvider>
        </ConfigProvider>
      </UserContextProvider>
    </>

  );
};

export default OnRamp;
