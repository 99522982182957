import { useGetConversionOptions } from 'api/OnRamp/useGetConversionOptions';
import { useGetCryptoCurrency } from 'api/OnRamp/useGetCryptoCurrency';
import { useGetQuote } from 'api/OnRamp/useGetQuote';
import { Response } from 'hooks/networking/useCancellableNetworkRequest';
import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import useFiatCurrencyState, { FiatCurrencyState } from 'state/onramp/currency';
import useGetWithdrawNetworkState, { WithdrawNetworkState } from 'state/onramp/network';
import { Currency } from 'types/currency';
import useInputValue, { InputValue } from 'types/input-value';
import { Quote } from 'types/quote';
import { useUserContext } from './UserContext';
import { useAuth } from './auth/use-auth';
import { usePaymentMethod } from 'state/onramp/payment';

type OnrampContextProviderProps = {
  children: ReactNode,
  fiatAmount?: number
  fiatSymbol?: string
  suggestedFiatSymbol?: string
  cryptoAmount?: number
  network?: string
  withdrawAddress?: string
  cryptoSymbol?: string
  suggestedCryptoSymbol?: string
  paymentMethod: string
}

type PaymentMethodValidationType = {
  isValid: boolean,
  isEnabled: boolean
}

type OnrampState = {
  fiatAmount: InputValue<number>
  cryptoAmount: InputValue<number>
  lockAmounts: () => void
  withdrawNetworkState: WithdrawNetworkState
  withdrawAddress: InputValue<string>
  fiatCurrencyState: FiatCurrencyState
  cryptoCurrencyState: InputValue<Currency>
  quote: Response<Quote>
  paymentMethods: any
  selectedPaymentMethod: InputValue<string>
  selectedPaymentApp: InputValue<string>
  paymentMethodValidation: PaymentMethodValidationType | undefined
}

// @ts-ignore
export const OnrampContext = createContext<OnrampState>({});

export const OnrampContextProvider: FC<OnrampContextProviderProps> = (props) => {

  const { children, network, cryptoSymbol, suggestedCryptoSymbol, paymentMethod } = props
  const { clientId }: any = useAuth()

  console.log("OnrampContextProvider ~ clientId", clientId)

  const currencyData = useGetConversionOptions()

  const { user } = useUserContext()
  const userCountry = user?.getCountry()?.iso3

  const fiatCurrencyState = useFiatCurrencyState(currencyData.data ?? [], userCountry, props.fiatSymbol, props.suggestedFiatSymbol)
  const cryptoCurrencyState = useGetCryptoCurrency(cryptoSymbol, suggestedCryptoSymbol)

  const isMudrexTest = clientId === "18e963ea-39fd-4a1b-b1e6-decbfe791d31"
  const defaultCryptoAmount = isMudrexTest ? 5 : 20
  const fiatAmount = useInputValue(props.fiatAmount, 0, props.fiatAmount != undefined, "fiat_amount")
  const cryptoAmount = useInputValue(props.cryptoAmount, defaultCryptoAmount, props.cryptoAmount != undefined, "crypto_amount")
  const [lastEdited, setLastEdited] = useState<"fiat" | "crypto">(props.fiatAmount ? "fiat" : "crypto")

  const withdrawNetworkState = useGetWithdrawNetworkState(cryptoCurrencyState.value.symbol, network, network ? true : false)
  const withdrawAddress = useInputValue(props.withdrawAddress, "", Boolean(props.withdrawAddress), "withdraw_address")

  const fiatSymbol = fiatCurrencyState.fiatCurrency?.value.symbol ?? "EUR"
  const crypto = cryptoCurrencyState.value.symbol
  const quote = useGetQuote({
    fiatSymbol,
    cryptoSymbol: crypto,
    network: withdrawNetworkState.selectedNetwork?.value.id,
    cryptoAmount: cryptoAmount,
    fiatAmount: fiatAmount,
    clientId: clientId
  })
  const kycCountry = user?.getCountry()?.iso3;

  const paymentData = usePaymentMethod({ cryptoSymbol: crypto, paymentMethod, fiatCurrencyState, kycCountry, })

  useEffect(() => {
    if (!quote.data) {
      return;
    }
    if (lastEdited === "fiat") {
      const number = Number((fiatAmount.value / quote.data?.basePrice - quote.data!.feeBreakup?.networkFee).toFixed(2));
      console.log("useEffect ~ number=>", number)
      cryptoAmount.setValue?.(number > 0 ? number : 0);
    } else if (lastEdited === "crypto") {
      const calculated = (Number(cryptoAmount.value) +
        quote.data!.feeBreakup?.networkFee) *
        quote.data!.basePrice
      const number = Number(calculated.toFixed(2));

      fiatAmount.setValue?.(number > 0 ? number : 0);
    }
  }, [quote, fiatAmount, cryptoAmount]);

  return (
    <OnrampContext.Provider value={
      {
        fiatAmount: { ...fiatAmount, setValue: (value: number) => { setLastEdited("fiat"); fiatAmount.setValue?.(value) } },
        cryptoAmount: { ...cryptoAmount, setValue: (value: number) => { setLastEdited("crypto"); cryptoAmount.setValue?.(value) } },
        withdrawNetworkState,
        fiatCurrencyState,
        cryptoCurrencyState,
        quote,
        withdrawAddress,
        lockAmounts: () => {
          if (lastEdited === "fiat") {
            fiatAmount.setLocked?.(true)
          } else if (lastEdited === "crypto") {
            cryptoAmount.setLocked?.(true)
          }
        },
        paymentMethods: paymentData?.data,
        selectedPaymentMethod: paymentData?.selectedPaymentMethod,
        selectedPaymentApp: paymentData?.selectedPaymentApp,
        paymentMethodValidation: paymentData?.paymentMethodValidation
      }
    } > {children} </OnrampContext.Provider>
  )
};

export const useOnrampContext = () => useContext(OnrampContext);